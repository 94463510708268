import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../actions/modal';
import { BOOKING_STATUS, INNS } from '../constants';
import { formatDateToString } from '../utils/shared';

function CalDateForList(props) {
  const dispatch = useDispatch();
  const {bookings} = useSelector((state) => state);
  const {date} = props;
  const formatDate = formatDateToString(date);
  const dayOfWeek = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'];
  const bookingsOfDay = bookings.filter((booking) => {
    return (booking.startDate <= formatDate && formatDate < booking.endDate);
  });
  const pastDate = formatDate < formatDateToString(new Date()) ? 'past-date' : '';

  return (
    <tbody>
      <tr>
        <td className="calendar-list-td-date">{date.getDate()}</td>
        <td className="calendar-list-td-day">{dayOfWeek[date.getDay()]}</td>
        {INNS.map((inn, index) => {
          let numbersOfPaying = {}
          let numberOfBookings = 0;
          inn.rooms.map((room) => {
            const roomCapacity = room.capacity
            const bookingsOfRoom = bookingsOfDay.filter((booking) => booking.inn === inn.name && booking.room === room.name)
            if (room.dormitory) {
              // ドミトリーは人数が上限に達しているかどうか判断する
              let numberOfPaying = 0
              for (let i = 0; i < bookingsOfRoom.length; i++) {
                const event = bookingsOfRoom[i];
                if (event.numberOfPaying === 0) {
                  // 人数のない予約（人数で判断する前の予約）があれば埋まったものとする
                  numberOfPaying += roomCapacity;
                  break;
                } else numberOfPaying += event.numberOfPaying;
              }
              if (numberOfPaying >= roomCapacity) numberOfBookings++
              numbersOfPaying[room.name] = numberOfPaying;
            } else {
              // それ以外は予約があるかどうかで判断する
              if (bookingsOfRoom.length > 0) {
                numberOfBookings++;
                numbersOfPaying[room.name] = roomCapacity;
              }
            }
          })

          const numberOfRooms = inn.rooms ? inn.rooms.length : 1;
          const numberOfRoomsRemaining = numberOfRooms - numberOfBookings;
          const bookingStatus = numberOfRoomsRemaining === 0 ? BOOKING_STATUS.FULL
            : inn.rooms && numberOfBookings === 1 ? BOOKING_STATUS.FEW : BOOKING_STATUS.VACANT;

          const openModal = () => {
            let _rooms = [];
            inn.rooms.forEach((room) => {
              if (room.name in numbersOfPaying) {
                // 予約があれば人数が上限に達しているか
                if (numbersOfPaying[room.name] < room.capacity) {
                  // 人数が上限に達していなければ部屋を選択できる
                  // 補足：ドミトリー以外は、予約があれば実際の人数に関わらずnumbersOfPayingに上限人数を設定しているため同じ条件で確認できる
                  _rooms.push(room)
                }
              } else {
                // 予約がなければ部屋を選択できる
                _rooms.push(room)
              }
            })
            const _checkOut = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            const modal = {
              open: true,
              inn: inn.name,
              rooms: _rooms,
              checkIn: date,
              checkOut: _checkOut,
              bookingsOfDay,
            };
            dispatch(setModal(modal));
          };

          if (bookingStatus !== BOOKING_STATUS.FULL) {
            return (
              <td key={index} className={`calendar-list-td-inn clickable-true ${pastDate}`} id={inn.name} onClick={openModal}>{bookingStatus}</td>
            )
          } else {
            return (
              <td key={index} className={`calendar-list-td-inn clickable-false ${pastDate}`} id={inn.name}>{bookingStatus}</td>
            )
          }
        })}
      </tr>
    </tbody>
  );
}

export default CalDateForList;