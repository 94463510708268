import { BsCircle, BsTriangle, BsXLg } from 'react-icons/all';

export const CALENDAR = {
  'NUMBER_OF_BOOKABLE_MONTH': 120
};

export const TEXTS = {
  'introductions': [
    '〈小川まちやど〉とは、小川町全体を一つの宿ととらえて宿泊者をお迎えする新しい宿のかたちです。',
    '町の建物をリノベーションした客室はすべて素泊まり。',
    'せっかく滞在するのなら、外に繰り出して地元の人に混じってご飯を食べたり、近くの温泉に行ってみたり、この町の風景に浸かってみるのはいかがでしょうか？',
    '槻川を中心に広がる小川町の中心地は、歴史ある町並みの中で新旧の個人店が商売を営み、活気に溢れています。',
    '少し行けば田畑も山もあり、豊かな自然にすぐ触れることもできます。',
    'コンパクトな町ならではの楽しみ方を、この町で暮らす私たちが案内人となってご紹介します。',
    'どんどん町に繰り出して、どこにも載っていないあなただけの楽しみ方をぜひ見つけてみてください。',
  ],
  'checkInOuts': [
    '・チェックイン可能時間：15:00〜20:00',
    '　※15時以前、20時以降のチェックインご希望の方はご要望欄に記載ください。',
    '',
    '・チェックアウト時間：11:00',
    '　※チェックアウト後の利用も別途料金で可能です。ご希望の方はご要望欄に記載ください。',
  ],
  'email': 'ogawanoyado2022@gmail.com',
  'sns': {
    'instagram': 'https://www.instagram.com/ogawamachiyado/',
  },
  'hvacs': [
    '以下の期間、各宿一泊につき500円いただいております。',
    '・7〜9月：冷房',
    '・11〜3月：暖房＋灯油',
  ],
  'accesses': [
    '・電車：東武東上線・JR八高線「小川町駅」から徒歩5分圏内',
    '・お車：関越自動車道「嵐山小川IC」から15分ほど',
  ],
  'cancelPolicies': [
    'お客さま都合によるキャンセルにつきましては、キャンセル料のお支払いをお願いしております。',
    '・宿泊日の2〜3日前：宿泊料金の50%',
    '・前日・当日：宿泊料金の100%',
  ],
}

export const INNS = [
  {
    'id': 'tsuki',
    'active': true,
    'name': 'ツキ',
    'introduction': {
      'slogan': 'ゆったり自然を満喫するお宿・ツキ',
      'descriptions': [
          '街道沿いにある広々とした古民家。',
          '目の前を流れる槻川を見渡せるラウンジや、まるで川に浸りながら星を眺めているような開放的なお風呂をお楽しみいただけます。',
      ],
      'link': 'https://ogawanoyado.com/top/about/tsuki/',
      'price': '25,000 / 1〜2名（平日の1名利用は12,500）\n30,000 / 3〜4名\n35,000 / 5〜6名\n37,000 / 7名\n39,000 / 8名\n※小学生以下は添い寝に限り無料',
      'capacity': '8名',
    },
    'rooms': [
      {
        'name': '一棟貸し',
        'price': {
          'adult': null,
          'child': null,
          'table': {
            1: {'weekday': 12500, 'holiday': 25000},
            2: {'weekday': 25000, 'holiday': 25000},
            3: {'weekday': 30000, 'holiday': 30000},
            4: {'weekday': 30000, 'holiday': 30000},
            5: {'weekday': 35000, 'holiday': 35000},
            6: {'weekday': 35000, 'holiday': 35000},
            7: {'weekday': 37000, 'holiday': 37000},
            8: {'weekday': 39000, 'holiday': 39000},
          }
        },
        'capacity': 8,
        'formLabel': {
          'numberOfAdults': '大人人数',
          'numberOfChildren': 'お子様人数（寝具あり）',
          'numberOfFree': 'お子様人数（寝具なし）',
        },
      },
    ],
  },
  {
    'id': 'sanshimai',
    'active': true,
    'name': '三姉妹',
    'introduction': {
      'slogan': '町に溶け込む古民家宿・三姉妹',
      'descriptions': [
        '商店街にほど近い「北裏通り」に面したおよそ築100年のコンパクトな古民家。',
        '数週間〜数ヶ月の貸切利用も可能です。',
      ],
      'link': 'https://ogawanoyado.com/top/about/sanshimai/',
      'price': '7,000 / 1名\n※小学生以下は添い寝に限り無料',
      'capacity': '4名',
    },
    'rooms': [
      {
        'name': '一棟貸し',
        'price': {
          'adult': 7000,
          'child': 7000,
        },
        'discount': {
          'adult': 0,
          'child': 0,
        },
        'capacity': 4,
        'formLabel': {
          'numberOfAdults': '大人人数',
          'numberOfChildren': 'お子様人数（寝具あり）',
          'numberOfFree': 'お子様人数（寝具なし）',
        },
      }
    ],
  },
  {
    'id': 'sithouse',
    'active': true,
    'name': 'ジットハウス',
    'introduction': {
      'slogan': '気軽に泊まれるドミトリー・ジットハウス',
      'descriptions': [
        '当日チェックインも可能なドミトリー形式のお宿。',
        'レンタルスタジオとカフェバーが入居する複合ビルの一室にあります。',
      ],
      'link': 'https://ogawanoyado.com/top/about/jittohouse/',
      'price': 'ドミトリー : 3,000 / 1名\n個室 : 3,000 / 1名\n※小学生以下は添い寝に限り無料',
      'capacity': 'ドミトリー（二段ベット） : 4名\n個室 : 2名',
    },
    'rooms': [
      {
        'name': 'ドミトリー',
        'dormitory': true,
        'price': {
          'adult': 3000,
          'child': 3000,
        },
        'capacity': 4,
        'formLabel': {
          'numberOfAdults': 'ご宿泊人数（中学生以上）',
          'numberOfChildren': 'ご宿泊人数（小学生）',
          'numberOfFree': null,
        },
      },
      {
        'name': '個室',
        'price': {
          'adult': 3000,
          'child': 3000,
        },
        'capacity': 2,
        'formLabel': {
          'numberOfAdults': 'ご宿泊人数（中学生以上）',
          'numberOfChildren': 'ご宿泊人数（小学生）',
          'numberOfFree': null,
        },
      },
    ],
  },
];

export const PLANS = [
  {
    'id': 'coworking',
    'name': 'コワーキング利用つき | コワーキングロビーNESTo',
    'copy': '石蔵を改修したコワーキングロビーNESToを利用できる',
    'context': '宿泊当日・翌日にコワーキングスペースを利用することができます。',
    'image': 'images/plan_coworking.png',
    'price': 1500,
    'unit': '人数',
  },
  {
    'id': 'rentacycle',
    'name': 'レンタサイクル利用つき | おいでなせえ小川町',
    'copy': '夜・朝の時間帯も、自転車で遠くまで探検できる',
    'context': '宿泊当日・翌日にレンタサイクルを使用することができます。',
    'image': 'images/plan_rentacycle.png',
    'price': 1500,
    'unit': '人数',
  },
  {
    'id': 'ingredient',
    'name': '小川町の野菜・調味料セットつき | OGAWA\'N Project',
    'copy': '有機の里・小川町を作って、食べて、体感する',
    'context': '旬の食材(2〜3人分)を小川まちやどがセレクトし、お宿に準備いたします。',
    'image': 'images/plan_ingredient.png',
    'price': 3000,
    'unit': 'セット数',
  },
]

export const BOOKING_STATUS = {
  VACANT: <BsCircle />,
  FEW: <BsTriangle />,
  FULL: <BsXLg />,
};

export const CUSTOMER_TYPE = {
  ADULT: 'adult',
  CHILD: 'child',
}

export const ARRIVAL_TIMES = [
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
]

export const HVAC_UNIT_PRICE = 500;
