import React from 'react';

function Plan(props) {
  const {plan} = props;
  return (
    <div style={{marginBottom: '2em'}}>
      <p>・{plan.name}</p>
      <img src={plan.image} alt="plan" style={{marginLeft: '1em', width: '100%', maxWidth: '640px'}} />
      <div style={{marginLeft: '1em'}}>
        <p>{plan.context}</p>
        <p>料金；{plan.price.toLocaleString()} / 1{plan.unit}</p>
      </div>
    </div>
  );
}

export default Plan;