import React from 'react';
import {PLANS} from '../constants';
import Plan from './Plan';

const introductionStyle = {
  'fontSize': 'small',
  'textAlign': 'left',
  'margin': '2em auto',
  'lineHeight': '1.6em',
};

function Plans() {
  return (
    <div className="inns">
      <div style={introductionStyle}>
        <p style={{fontSize: '1.1em', marginLeft: '1em', marginBottom: 0}}>オプションプラン</p>
        <hr/>
        <div style={{width: '95%', margin: '0.8em auto'}}>
          <p style={{marginBottom: '2em'}}>ご宿泊に際し、以下の各種プランをお選びいただけます。</p>
          {PLANS.map((plan, index) => <Plan key={index} plan={plan}/>)}
        </div>
      </div>
    </div>
  );
}

export default Plans;