import axios from 'axios'
import { CALENDAR } from '../constants';
import { sendMail } from './emailjs';
import { getFirstDay } from './shared';

export function getInitialData() {
  return Promise.all([
    getEvents(),
  ]).then(([events]) => ({
    events,
  }));
}

export function getEvents() {
  return new Promise(async (resolve, reject) => {
    // TODO: 環境にあわせて変更
    const url = "https://ogawanoyado.an.r.appspot.com/calendar";
    // const url = "http://localhost:8000/calendar";

    const now = new Date();
    const firstDayOfThisMonth = getFirstDay(now);
    const firstDayOfUnBookableMonth = getFirstDay(new Date(), CALENDAR.NUMBER_OF_BOOKABLE_MONTH + 1);
    const lastDayOfBookable = new Date(
      firstDayOfUnBookableMonth.getFullYear(),
      firstDayOfUnBookableMonth.getMonth(),
      firstDayOfUnBookableMonth.getDate() - 1
    );

    axios
      .get(url, {
        params: {
          time_min: firstDayOfThisMonth.toISOString(),
          time_max: lastDayOfBookable.toISOString(),
        }
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createEventToCalendar = async (formData) => {
  return new Promise(async (resolve, reject) => {
    // TODO: 環境にあわせて変更
    const url = "https://ogawanoyado.an.r.appspot.com/calendar";
    // const url = "http://localhost:8000/calendar";
    const {inn, checkIn, checkOut, room, name, phoneNumber, email, address, numberOfAdults, numberOfChildren, numberOfFree, arrivalTime, plans, magazine, comments, totalAmount, hvacAmount} = formData;
    const numberOfPaying = Number(numberOfAdults) + Number(numberOfChildren);
    const booking = {
      summary: [inn, room, '仮予約', numberOfPaying].join(','),
      start: {
        date: checkIn
      },
      end: {
        date: checkOut
      },
      description: JSON.stringify({
        'お名前': name,
        '電話番号': phoneNumber,
        'メールアドレス': email,
        '住所': address,
        'ご宿泊人数（中学生以上）/大人人数': numberOfAdults,
        'ご宿泊人数（小学生）/お子様人数（寝具あり）': numberOfChildren,
        'お子様人数（寝具なし）': numberOfFree,
        '到着予定時刻': arrivalTime,
        'オプションプラン': plans,
        '宿からの情報マガジンメール': magazine,
        'その他ご質問、ご要望など': comments,
        '合計金額': totalAmount,
        '内冷暖房料金': hvacAmount
      }),
    };

    axios
      .post(url, booking)
      .then((result) => {
        sendMail(email, name, inn, room, checkIn, checkOut, numberOfAdults, numberOfChildren, numberOfFree, arrivalTime, plans, magazine, comments, totalAmount, hvacAmount);
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
};