import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_TYPE, HVAC_UNIT_PRICE, INNS } from '../constants';
import { formatDateToString } from '../utils/shared';
import * as holiday_jp from '@holiday-jp/holiday_jp';

function findInn(innName) {
  return INNS.find((inn) => inn.name === innName);
}

function findRoom(inn, roomName) {
  return inn.rooms.find((room) => room.name === roomName);
}

function calculateAmount(inn, room, customerType, numberOfPeople) {
  const price = customerType === CUSTOMER_TYPE.ADULT ? room.price.adult : room.price.child;
  const discount = room.discount ? customerType === CUSTOMER_TYPE.ADULT ? room.discount.adult : room.discount.child : 0;
  const amount = price - discount * (numberOfPeople - 1);
  return amount * numberOfPeople;
}

function calculateAmountForTable(room, numberOfPeople) {
  return room.price.table[numberOfPeople];
}

function calculatePriceOfDay(innName, roomName, numberOfAdults, numberOfChildren) {
  const inn = findInn(innName);
  const room = findRoom(inn, roomName);
  if (room.price.table) {
    const numberOfPeople = Number(numberOfAdults) + Number(numberOfChildren);
    return calculateAmountForTable(room, numberOfPeople)
  } else {
    const amountOfAdults = calculateAmount(inn, room, CUSTOMER_TYPE.ADULT, numberOfAdults);
    const amountOfChildren = calculateAmount(inn, room, CUSTOMER_TYPE.CHILD, numberOfChildren);
    const amountOfPeople = amountOfAdults + amountOfChildren;
    return {
      weekday: amountOfPeople,
      holiday: amountOfPeople
    };
  }
}

function Confirmation(props) {
  const {modal, formData} = useSelector((state) => state);
  const days = (formData.checkOut - formData.checkIn) / 86400000;
  const priceOfDay = calculatePriceOfDay(modal.inn, formData.room, formData.numberOfAdults, formData.numberOfChildren);
  const holidays = holiday_jp.between(formData.checkIn, formData.checkOut);
  let holidayCount = 0;
  const startDate = new Date(formData.checkIn);
  const endDate = new Date(formData.checkOut);
  for (let date = new Date(startDate); date < endDate; date.setDate(date.getDate() + 1)) {
    if (holidays.some(holiday => holiday.date.getFullYear() === date.getFullYear() && holiday.date.getMonth() === date.getMonth() && holiday.date.getDate() === date.getDate()) || date.getDay() === 0 || date.getDay() === 6) {
      holidayCount++;
    }
  }
  const totalAmountForWeekday = priceOfDay.weekday * (days - holidayCount);
  const totalAmountForHoliday = priceOfDay.holiday * holidayCount;
  // 冷暖房料金を求める
  let hvacDays = 0;
  for (let date = new Date(startDate); date < endDate; date.setDate(date.getDate() + 1)) {
    if ([1, 2, 3, 7, 8, 9, 11, 12].includes(date.getMonth() + 1)) {
      hvacDays++;
    }
  }
  const hvacAmount = HVAC_UNIT_PRICE * hvacDays;
  // オプションプラン料金を求める
  let planAmount = 0;
  formData.plans.forEach((plan) => {
    if (plan.checked === true) {
      planAmount += plan.price * plan.quantity;
    }
  });

  const totalAmount = totalAmountForWeekday + totalAmountForHoliday + hvacAmount + planAmount
  const [formLabel, setFormLabel] = useState();

  useEffect(() => {
    setFormLabel(modal.rooms.find((_room) => _room.name === formData.room).formLabel);
    props.setValue('totalAmount', totalAmount.toLocaleString() + "円");
    props.setValue('hvacAmount', hvacAmount.toLocaleString() + "円");
  }, [modal])

  return (
    <div className="confirmation">
      <p>以下の内容でご予約を受付いたします。</p>
      <dl>
        <dt>宿泊施設</dt>
        <dd>{modal.inn}</dd>
        <dt>チェックイン</dt>
        <dd>{formatDateToString(formData.checkIn)}</dd>
        <dt>チェックアウト</dt>
        <dd>{formatDateToString(formData.checkOut)}</dd>
        <dt>お部屋</dt>
        <dd>{formData.room}</dd>
        <dt>お名前</dt>
        <dd>{formData.name}</dd>
        <dt>住所</dt>
        <dd>{formData.address}</dd>
        <dt>電話番号</dt>
        <dd>{formData.phoneNumber}</dd>
        <dt>メールアドレス</dt>
        <dd>{formData.email}</dd>
        <dt>{formLabel?.numberOfAdults}</dt>
        <dd>{formData.numberOfAdults}</dd>
        <dt>{formLabel?.numberOfChildren}</dt>
        <dd>{formData.numberOfChildren}</dd>
        {formLabel?.numberOfFree &&
            <>
              <dt>{formLabel.numberOfFree}</dt>
              <dd>{formData.numberOfFree}</dd>
            </>
        }
        <dt>到着予定時刻</dt>
        <dd>{formData.arrivalTime}</dd>
        <dt>オプションプラン</dt>
        <dd>
          {formData.plans.map((plan, index) => {
            return plan.checked ? (<p>{plan.name}：{plan.quantity}{plan.unit.slice(0, -1)}<br/></p>) : null;
          })}
        </dd>
        <dt>宿からの情報マガジンメール</dt>
        <dd>{formData.magazine ? "受け取る" : "受け取らない"}</dd>
        <dt>その他ご質問、ご要望など</dt>
        <dd>{formData.comments}</dd>
        <dt>合計金額</dt>
        <dd>{totalAmount.toLocaleString()} 円</dd>
        <dt>（内 冷暖房料金）</dt>
        <dd>{hvacAmount.toLocaleString()} 円</dd>
      </dl>
      <p>お間違いがないことをご確認のうえ「予約する」を押してください</p>
    </div>
  );
}

export default Confirmation;