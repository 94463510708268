import React from 'react';
import { INNS } from '../constants';
import Inn from './Inn';

function Inns() {
  return (
    <div className="inns">
      {INNS.map((inn, index) => (
        <Inn key={index} inn={inn}/>
      ))}
    </div>
  );
}

export default Inns;