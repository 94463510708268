import { CLEAR_FORM_DATA, SET_FORM_DATA } from '../actions/formData';

export default function formData(state = {}, action) {
  switch (action.type) {
    case SET_FORM_DATA:
      return action.formData;
    case CLEAR_FORM_DATA:
      return {};
    default:
      return state;
  }
}