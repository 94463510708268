export const SET_FORM_DATA = 'SET_FORM_DATA';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

export function setFormData(formData) {
  return {
    type: SET_FORM_DATA,
    formData
  };
}

export function clearFormData() {
  return {
    type: CLEAR_FORM_DATA,
    formData: {}
  };
}
