import { Public } from '@mui/icons-material';
import { TEXTS } from '../constants';
import { Link } from '@material-ui/core';

const titleStyle = {
  'marginTop': '1.5em',
  'marginBottom': '2em',
};

const introductionStyle = {
  'fontSize': 'small',
  'textAlign': 'left',
  'margin': '2em auto',
  'lineHeight': '1.6em',
};

function Header() {
  return (
    <>
      <div>
        <div style={titleStyle}>
          ご予約・お問い合わせ
        </div>
        <div style={{marginBottom: '1em'}}>
          <a href="top" target="_blank">
            <img src="images/header_logo.svg" alt="top page" style={{width: '5em'}} />
          </a>
        </div>
        <div>
          <Link href="https://note.com/ogawa_machiyado/n/n8ed368335a43" target="_blank">
            <Public />
            English
          </Link>
        </div>
        <div className='introduction' style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>小川まちやどについて</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.introductions.map((introduction, index) => <div key={index}>{introduction}<br/></div>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;