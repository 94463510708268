import React from 'react';
import { useSelector } from 'react-redux';
import { BOOKING_STATUS } from '../constants';
import BookingForm from './BookingForm';
import CalController from './CalController';
import CalDateForList from './CalDateForList';
import CalHeader from './CalHeader';
import CalHeaderForList from './CalHeaderForList';
import CalWeek from './CalWeek';

function Calendar() {
  const {dates} = useSelector((state) => state);
  const {firstDayOfMonth, startDayOfCalendar} = dates;

  let firstDayOfWeeks = [];

  for (let i = 0; i < 6; i++) {
    const date = new Date(
      startDayOfCalendar.getFullYear(),
      startDayOfCalendar.getMonth(),
      startDayOfCalendar.getDate() + i * 7);
    firstDayOfWeeks.push(date);
  }

  let datesOfMonth = [];
  const daysOnMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0).getDate();
  for (let i = 1; i <= daysOnMonth; i++) {
    const date = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      i);
    datesOfMonth.push(date);
  }

  return (
    <div className="calendar">
      <p className="calendar-title">ご予約</p>
      <hr/>
      <div className="calendar-comment">
        <p>ご予約は、以下のカレンダーからご宿泊希望日の {BOOKING_STATUS.VACANT} または {BOOKING_STATUS.FEW} を選択してください。</p>
        <p>{BOOKING_STATUS.VACANT} : 空きあり</p>
        <p>{BOOKING_STATUS.FEW} : 残りわずか</p>
        <p>{BOOKING_STATUS.FULL} : 満室／予約不可</p>
      </div>
      <CalController/>
      <table className="calendar-list">
        <CalHeaderForList/>
        {datesOfMonth.map((date, index) => (
          <CalDateForList key={index} date={date}/>
        ))}
      </table>
      <div className="calendar-table">
        <CalHeader/>
        {firstDayOfWeeks.map((date, index) => (
          <CalWeek key={index} startDayOfWeek={date}/>
        ))}
      </div>
      <BookingForm/>
    </div>
  );
}

export default Calendar;