import { send } from 'emailjs-com';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

export function sendMail(email, name, place, room, checkIn, checkOut, numberOfAdults, numberOfChildren, numberOfFree, arrivalTime, plans, magazine, comments, totalAmount, hvacAmount) {
  const data = {
    email,
    name,
    place,
    room,
    checkIn,
    checkOut,
    numberOfAdults,
    numberOfChildren,
    numberOfFree,
    arrivalTime,
    plans,
    magazine,
    comments,
    totalAmount,
    hvacAmount,
  };

  send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then(
    function (response) {
      console.log(response.status, response.text);
    },
    function (err) {
      console.log(err);
    }
  );
}