import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDates } from '../actions/dates';
import { CALENDAR } from '../constants';
import { getFirstDay } from '../utils/shared';

function CalController() {
  const dispatch = useDispatch();
  const {dates} = useSelector((state) => state);
  const {firstDayOfMonth} = dates;
  const firstDayOfThisMonth = getFirstDay(new Date());
  const firstDayOfBookableLastMonth = getFirstDay(new Date(), CALENDAR.NUMBER_OF_BOOKABLE_MONTH);
  const isMinMonth = firstDayOfMonth.getTime() === firstDayOfThisMonth.getTime();
  const isMaxMonth = firstDayOfMonth.getTime() === firstDayOfBookableLastMonth.getTime();

  const handlePrevMonth = () => {
    if (!isMinMonth) {
      const prevFirstDate = getFirstDay(firstDayOfMonth, -1);
      dispatch(setDates(prevFirstDate));
    }
  };
  const handleNextMonth = () => {
    if (!isMaxMonth) {
      const nextFirstDate = getFirstDay(firstDayOfMonth, 1);
      dispatch(setDates(nextFirstDate));
    }
  };

  return (
    <div className="calController">
      <div className={'display-' + !isMinMonth} onClick={handlePrevMonth}>←</div>
      <div className="calYearAndMonth"> {firstDayOfMonth.getFullYear()}年{firstDayOfMonth.getMonth() + 1}月</div>
      <div className={'display-' + !isMaxMonth} onClick={handleNextMonth}>→</div>
    </div>
  );
}

export default CalController;