import { TEXTS } from '../constants';

const introductionStyle = {
  'fontSize': 'small',
  'textAlign': 'left',
  'margin': '2em auto',
  'lineHeight': '1.6em',
};

function Footer() {
  return (
    <>
      <div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>冷暖房料金</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.hvacs.map((hvac, index) => <div key={index}>{hvac}<br/></div>)}
          </div>
        </div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>アクセス</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.accesses.map((access, index) => <div key={index}>{access}<br/></div>)}
          </div>
        </div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>キャンセルポリシー</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.cancelPolicies.map((cancelPolicy, index) => <div key={index}>{cancelPolicy}<br/></div>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;