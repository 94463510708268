import { HIDE_LOADING, SHOW_LOADING } from '../actions/loading';

export default function loading(state = null, action) {
  switch (action.type) {
    case SHOW_LOADING:
    case HIDE_LOADING:
      return action.loading;
    default:
      return state;
  }
}