import { combineReducers } from 'redux';
import bookings from './bookings';
import dates from './dates';
import formData from './formData';
import loading from './loading';
import modal from './modal';

export default combineReducers({
  loading,
  bookings,
  dates,
  modal,
  formData,
});