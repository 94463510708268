import React from 'react';
import { TEXTS } from '../constants';
import Obfuscate from 'react-obfuscate';
import { Link } from '@material-ui/core';
import InstagramIcon from '@mui/icons-material/Instagram';

const introductionStyle = {
  'fontSize': 'small',
  'textAlign': 'left',
  'margin': '2em auto',
  'lineHeight': '1.6em',
};

function Info() {
  return (
    <>
      <div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>チェックイン・チェックアウト</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.checkInOuts.map((checkInOut, index) => <div key={index}>{checkInOut}<br/></div>)}
          </div>
        </div>
      </div>
      <div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>お問い合わせ</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            <Obfuscate email={TEXTS.email}/>
          </div>
        </div>
      </div>
      <div>
        <div style={introductionStyle}>
          <p style={{'fontSize': '1.1em', 'marginLeft': '1em', 'marginBottom': 0}}>SNS</p>
          <hr/>
          <div style={{width: '95%', margin: '0.8em auto'}}>
            {TEXTS.sns && (
              <>
                <Link href={TEXTS.sns.instagram} target="_blank">
                  {TEXTS.sns.instagram && <InstagramIcon/>}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;