import { getStartDate } from '../utils/shared';

export const SET_DATES = 'SET_DATES';

export function setDates(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return {
    type: SET_DATES,
    dates: {
      firstDayOfMonth,
      startDayOfCalendar: getStartDate(firstDayOfMonth)
    }
  };
}
