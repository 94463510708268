export const SET_MODAL = 'SET_MODAL';
export const SWITCH_STEP_ROOM = 'SWITCH_STEP_ROOM';
export const SWITCH_STEP_DETAIL = 'SWITCH_STEP_DETAIL';
export const SWITCH_STEP_PLAN = 'SWITCH_STEP_PLAN';
export const SWITCH_STEP_CONFIRM = 'SWITCH_STEP_CONFIRM';
export const STEP_ROOM = 'STEP_ROOM';
export const STEP_DETAIL = 'STEP_DETAIL';
export const STEP_PLAN = 'STEP_PLAN';
export const STEP_CONFIRM = 'STEP_CONFIRM';

export function setModal(modal) {
  const {open, inn, rooms, checkIn, checkOut, bookingsOfDay} = modal;
  return {
    type: SET_MODAL,
    modal: {
      open,
      step: STEP_ROOM,
      inn: inn,
      rooms,
      checkIn,
      checkOut,
      bookingsOfDay,
    }
  };
}

export function switchStepRoom() {
  return {
    type: SWITCH_STEP_ROOM,
    step: STEP_ROOM
  };
}

export function switchStepDetail() {
  return {
    type: SWITCH_STEP_DETAIL,
    step: STEP_DETAIL
  };
}

export function switchStepPlan() {
  return {
    type: SWITCH_STEP_PLAN,
    step: STEP_PLAN
  };
}

export function switchStepConfirm() {
  return {
    type: SWITCH_STEP_CONFIRM,
    step: STEP_CONFIRM
  };
}
