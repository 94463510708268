import {SET_MODAL, SWITCH_STEP_CONFIRM, SWITCH_STEP_DETAIL, SWITCH_STEP_PLAN, SWITCH_STEP_ROOM} from '../actions/modal';

export default function modal(state = {}, action) {
  switch (action.type) {
    case SET_MODAL:
      return action.modal;
    case SWITCH_STEP_ROOM:
    case SWITCH_STEP_DETAIL:
    case SWITCH_STEP_PLAN:
    case SWITCH_STEP_CONFIRM:
      return {
        ...state,
        step: action.step
      };
    default:
      return state;
  }
}