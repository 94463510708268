import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setFormData } from '../actions/formData';
import {
  setModal,
  STEP_CONFIRM,
  STEP_DETAIL,
  STEP_ROOM,
  switchStepConfirm,
  switchStepDetail,
  switchStepRoom,
} from '../actions/modal';
import { handleInitialData } from '../actions/shared';
import { ARRIVAL_TIMES, PLANS } from '../constants';
import { createEventToCalendar } from '../utils/api';
import { formatDateToString, formatStringToDate } from '../utils/shared';
import Confirmation from './Confirmation';

function BookingForm() {
  const dispatch = useDispatch();
  const {bookings, modal} = useSelector((state) => state);
  const [room, setRoom] = useState('');
  const [formLabel, setFormLabel] = useState();
  const [arrivalTime, setArrivalTime] = useState(ARRIVAL_TIMES[0]);
  const [capacity, setCapacity] = useState(9);
  const [dormitory, setDormitory] = useState(undefined);
  const [roomErrorText, setRoomErrorText] = useState('');
  const theme = createTheme({
    typography: {
      fontSize: 11,
    },
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: 'indianred',
        },
      },
    },
  });

  const closeModal = () => {
    dispatch(setModal({
      ...modal,
      open: false,
    }));
    setRoom('');
    setArrivalTime(ARRIVAL_TIMES[0]);
    reset({
      checkIn: '',
      checkOut: '',
      name: '',
      address: '',
      phoneNumber: '',
      email: '',
      confirmEmail: '',
      numberOfAdults: '',
      numberOfChildren: '',
      comments: '',
      totalAmount: '',
      hvacAmount: '',
      plans: PLANS.map(plan => ({ ...plan, checked: false, quantity: 1 })),
      magazine: false,
    });
  };

  const validationSchema = Yup.lazy((values) => {
    const numberOfAdults = isNaN(Number(values.numberOfAdults)) ? 0 : Number(values.numberOfAdults);
    const numberOfChildren = isNaN(Number(values.numberOfChildren)) ? 0 : Number(values.numberOfChildren);
    const numberOfPeople = numberOfAdults + numberOfChildren;

    return Yup.object().shape({
      room: Yup.string(),
      checkIn: Yup.date()
        .required('チェックインを入力してください'),
      checkOut: Yup.date()
        .required('チェックアウトを入力してください')
        .test('checkCompareCheckIn', 'チェックインより後の日付を選択してください',
          function (value) {
            const checkIn = formatStringToDate(getValues('checkIn'))
            return value > checkIn
          }
        )
        .test('checkUnBookableDate', 'ご予約できない日が含まれております',
          function (value) {
            if (dormitory === true) return true;
            const checkIn = formatStringToDate(getValues('checkIn'))
            const bookingsOfPeriod = bookings.filter(booking => booking.inn === modal.inn && booking.room === getValues('room'));
            let result = true
            bookingsOfPeriod.some((booking) => {
              const startDate = formatStringToDate(booking.startDate)
              const endDate = formatStringToDate(booking.endDate)
              if (endDate <= checkIn || startDate >= value) {
              } else if (value > startDate) {
                result = false
                return true;
              }
              return false;
            });
            return result;
          }
        )
        .test('checkUnBookableDateForDormitory', 'ご宿泊人数が宿泊できない日が含まれております',
          function (value) {
            if (dormitory === undefined) return true;
            const checkIn = formatStringToDate(getValues('checkIn'))
            const bookingsOfPeriod = bookings.filter(booking => booking.inn === modal.inn && booking.room === getValues('room'));
            const numberOfAdults = isNaN(Number(this.options.parent.numberOfAdults)) ? 0 : Number(this.options.parent.numberOfAdults);
            const numberOfChildren = isNaN(Number(this.options.parent.numberOfChildren)) ? 0 : Number(this.options.parent.numberOfChildren);
            const numberOfPeople = numberOfAdults + numberOfChildren;
            let result = true;
            for (let date = new Date(checkIn); date < new Date(value); date.setDate(date.getDate() + 1)) {
              const bookingsOfDay = bookingsOfPeriod.filter((booking) => {
                return formatStringToDate(booking.startDate) <= date && date < formatStringToDate(booking.endDate)
              });
              const numberOfPaying = bookingsOfDay.reduce((total, booking) => total + Number(booking.numberOfPaying), 0);
              const actualCapacity = Math.max(capacity - numberOfPaying, 0);
              if (numberOfPeople > actualCapacity) return false;
            }
            return result;
          }
        ),
      name: Yup.string()
        .required('お名前を入力してください'),
      address: Yup.string()
        .required('住所を入力してください'),
      phoneNumber: Yup.string()
        .required('電話番号を入力してください')
        .matches(/^([0-9]{10,11}$)/, '10〜11桁の数字を入力してください'),
      email: Yup.string()
        .required('メールアドレスを入力してください')
        .email('メールアドレスの形式が誤っています'),
      confirmEmail: Yup.string()
        .required('メールアドレス（確認）を入力してください')
        .email('メールアドレスの形式が誤っています')
        .oneOf([Yup.ref('email'), null], 'メールアドレスと一致していません'),
      numberOfAdults: Yup.string()
        .required('人数を入力してください')
        .matches(/^([1-9]$)/, '1〜9の数字を入力してください')
        .test('checkCapacityAdults', 'ご宿泊可能な人数を超えています',
          function (value) {
            const numberOfAdults = Number(value);
            const numberOfChildren = isNaN(Number(this.options.parent.numberOfChildren)) ? 0 : Number(this.options.parent.numberOfChildren);
            const numberOfPeople = numberOfAdults + numberOfChildren;
            return numberOfPeople <= capacity;
          }),
      numberOfChildren: Yup.string()
        .matches(/^([0-9]*$)/, '0〜9の数字を入力してください')
        .test('checkCapacityChildren', 'ご宿泊可能な人数を超えています',
          function (value) {
            const numberOfAdults = isNaN(Number(this.options.parent.numberOfAdults)) ? 0 : Number(this.options.parent.numberOfAdults);
            const numberOfChildren = Number(value);
            const numberOfPeople = numberOfAdults + numberOfChildren;
            return numberOfPeople <= capacity;
          }),
      numberOfFree: Yup.string()
        .matches(/^([0-9]*$)/, '0〜9の数字を入力してください'),
      arrivalTime: Yup.string(),
      comments: Yup.string(),
      totalAmount: Yup.string(),
      hvacAmount: Yup.string(),
      plans: Yup.array().of(
        Yup.object().shape({
          checked: Yup.boolean(),
          quantity: Yup.string()
            .when('checked', {
              is: true,
              then: Yup.string()
                .required('数量を入力してください')
                .matches(/^([1-9]$)/, '1〜9の数字を入力してください')
                .test('checkQuantity', 'ご利用可能な数量を超えています',
                  function (value) {
                    return Number(value) <= numberOfPeople;
                  }),
              otherwise: Yup.string().notRequired()
            })
        })
      ),
      magazine: Yup.boolean(),
    });
  })

  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      plans: PLANS.map(plan => ({ ...plan, checked: false, quantity: 1 })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'plans'
  });

  const watchedPlans = watch('plans');

  const handlePrev = () => {
    if (modal.step === STEP_DETAIL) {
      dispatch(switchStepRoom());
    } else if (modal.step === STEP_CONFIRM) {
      dispatch(switchStepDetail());
    }
  };

  const onNext = data => {
    if (modal.step === STEP_ROOM) {
      if (!room) {
        setRoomErrorText('お部屋を選択してください');
        return;
      }
      setValue('room', room);
      setFormLabel(modal.rooms.find((_room) => _room.name === room).formLabel);
      dispatch(switchStepDetail());
    } else if (modal.step === STEP_DETAIL) {
      dispatch(setFormData(data));
      dispatch(switchStepConfirm());
    }
  };

  const processing = useRef(false);

  const onSubmit = data => {
    if (processing.current) return;
    processing.current = true;

    const {room, checkIn, checkOut, name, address, phoneNumber, email, numberOfAdults, numberOfChildren, numberOfFree, arrivalTime, plans, magazine, comments, totalAmount, hvacAmount} = data;
    let editedPlans = plans.filter(plan => plan.checked)
      .map(plan => `${plan.name}：${plan.quantity}${plan.unit.slice(0, -1)}`)
      .join(', ');
    const args = {
      inn: modal.inn,
      checkIn: formatDateToString(checkIn),
      checkOut: formatDateToString(checkOut),
      room,
      name,
      address,
      phoneNumber,
      email,
      numberOfAdults,
      numberOfChildren,
      numberOfFree,
      arrivalTime,
      comments,
      totalAmount,
      hvacAmount,
      plans: editedPlans,
      magazine: magazine ? '受け取る' : '受け取らない'
    };

    createEventToCalendar(args)
      .then(() => {
        closeModal();
      })
      .then(() => {
        alert('ご予約（仮予約）を承りました。\n後日、担当者からご入力いただいたメールアドレスへ確認のメールをお送りいたします。\n今しばらくお待ちください。');
        dispatch(handleInitialData());
        process.current = false;
      });
  };

  useEffect(() => {
    setValue('checkIn', formatDateToString(modal.checkIn));
    setValue('checkOut', formatDateToString(modal.checkOut));
  }, [setValue, modal.checkIn, modal.checkOut])


  return (
    <Dialog className="booking-form" open={modal.open} onClose={closeModal} fullWidth maxWidth="xs">
      <DialogTitle>ご予約フォーム</DialogTitle>
      <DialogContent>
        <MuiThemeProvider theme={theme}>
          {modal.step === STEP_ROOM &&
            <>
              <p className="inn">宿泊施設 | {modal.inn}</p>
              <div>
                {modal.rooms?.length > 0 &&
                  <FormControl margin="dense" size="small" style={{width: 200}}>
                    <InputLabel required id="room-label">お部屋</InputLabel>
                    <Select
                      labelId="room-label"
                      id="room"
                      value={room}
                      onChange={(event) => {
                        if (event.target.value) {
                          setRoomErrorText('');
                        }
                        setRoom(event.target.value);
                        setCapacity(modal.rooms.find((room) => room.name === event.target.value).capacity);
                        setDormitory(modal.rooms.find((room) => room.name === event.target.value).dormitory);
                      }}
                      error={!!roomErrorText}
                    >
                      {modal.rooms.map((room, index) => (
                        <MenuItem key={index} value={room.name}>{room.name}</MenuItem>
                      ))}
                    </Select>
                    <Typography color="textSecondary">
                      {roomErrorText}
                    </Typography>
                  </FormControl>
                }
              </div>
            </>
          }
          {modal.step === STEP_DETAIL &&
            <>
              <p className="inn">宿泊施設 | {modal.inn}</p>
              {modal.rooms?.length > 0 &&
                <TextField
                  disabled
                  id="room"
                  name="room"
                  label="お部屋"
                  fullWidth
                  margin="dense"
                  {...register('room')}
                />
              }
              <TextField
                disabled
                type="date"
                id="checkIn"
                name="checkIn"
                label="チェックイン"
                style={{width: 200}}
                margin="dense"
                {...register('checkIn')}
              />
              <Typography color="textSecondary">
                {errors.checkIn?.message}
              </Typography>
              <TextField
                required
                type="date"
                id="checkOut"
                name="checkOut"
                label="チェックアウト"
                style={{width: 200}}
                margin="dense"
                {...register('checkOut')}
                error={!!errors.checkOut}
              />
              <Typography color="textSecondary">
                {errors.checkOut?.message}
              </Typography>
              <Grid>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="お名前"
                  fullWidth
                  margin="dense"
                  {...register('name')}
                  error={!!errors.name}
                />
                <Typography color="textSecondary">
                  {errors.name?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                    required
                    id="address"
                    name="address"
                    label="住所（都道府県・市町村まで）"
                    fullWidth
                    margin="dense"
                    {...register('address')}
                    error={!!errors.address}
                />
                <Typography color="textSecondary">
                  {errors.address?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  label="電話番号（数字のみ入力してください）"
                  fullWidth
                  margin="dense"
                  {...register('phoneNumber')}
                  error={!!errors.phoneNumber}
                />
                <Typography color="textSecondary">
                  {errors.phoneNumber?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="email"
                  id="email"
                  name="email"
                  label="メールアドレス"
                  fullWidth
                  margin="dense"
                  {...register('email')}
                  error={!!errors.email}
                />
                <Typography color="textSecondary">
                  {errors.email?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="email"
                  id="confirmEmail"
                  name="confirmEmail"
                  label="メールアドレス（確認）"
                  fullWidth
                  margin="dense"
                  {...register('confirmEmail')}
                  error={!!errors.confirmEmail}
                />
                <Typography color="textSecondary">
                  {errors.confirmEmail?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  required
                  id="numberOfAdults"
                  name="numberOfAdults"
                  label={formLabel?.numberOfAdults}
                  fullWidth
                  margin="dense"
                  {...register('numberOfAdults')}
                  error={!!errors.numberOfAdults}
                />
                <Typography color="textSecondary">
                  {errors.numberOfAdults?.message}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="numberOfChildren"
                  name="numberOfChildren"
                  label={formLabel?.numberOfChildren}
                  fullWidth
                  margin="dense"
                  {...register('numberOfChildren')}
                  error={!!errors.numberOfChildren}
                />
                <Typography color="textSecondary">
                  {errors.numberOfChildren?.message}
                </Typography>
              </Grid>
              {formLabel?.numberOfFree &&
                <Grid>
                  <TextField
                    id="numberOfFree"
                    name="numberOfFree"
                    label={formLabel?.numberOfFree}
                    fullWidth
                    margin="dense"
                    {...register('numberOfFree')}
                    error={!!errors.numberOfFree}
                  />
                  <Typography color="textSecondary">
                    {errors.numberOfChildren?.message}
                  </Typography>
                </Grid>
              }
              <Grid>
                <FormControl style={{width: 200}}>
                  <InputLabel id="arrival-time-label">到着予定時刻</InputLabel>
                  <Select
                    labelId="arrival-time-label"
                    id="arrival-time"
                    value={arrivalTime}
                    label="arrivalTime"
                    {...register('arrivalTime')}
                    onChange={(event) => setArrivalTime(event.target.value)}
                  >
                    {ARRIVAL_TIMES.map((arrivalTime) => (
                      <MenuItem key={arrivalTime} value={arrivalTime}>{arrivalTime}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <p style={{fontSize: '0.8em', marginTop: '2em'}}>
                  オプションプラン
                </p>
                {fields.map((field, index) => (
                  <React.Fragment key={field.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={watchedPlans[index].checked}
                          onChange={event => setValue(`plans.${index}.checked`, event.target.checked)}
                        />
                      }
                      label={field.name}
                    />
                    {watchedPlans[index].checked &&
                      <TextField
                        id={`plans.${index}.quantity`}
                        name={`plans.${index}.quantity`}
                        label={field.unit}
                        value={watchedPlans[index].quantity}
                        style={{ marginLeft: '1em' }}
                        {...register(`plans.${index}.quantity`)}
                        error={!!(errors.plans && errors.plans[index]?.quantity)}
                      />
                    }
                    <Typography color="textSecondary" style={{ marginLeft: '1em' }}>
                      {errors.plans && errors.plans[index]?.quantity && <p>{errors.plans[index]?.quantity.message}</p>}
                    </Typography>
                  </React.Fragment>
                ))}
              </Grid>
              <Grid>
                <p style={{fontSize: '0.8em', marginTop: '2em'}}>
                  宿からの情報マガジンメール
                </p>
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={watch('magazine')}
                          onChange={event => setValue('magazine', event.target.checked)}
                      />
                    }
                    label="受け取る"
                />
              </Grid>
              <Grid>
                <TextField
                  id="comments"
                  name="comments"
                  label="その他ご質問、ご要望など"
                  fullWidth
                  multiline
                  margin="dense"
                  {...register('comments')}
                />
              </Grid>
            </>
          }
          {modal.step === STEP_CONFIRM && <Confirmation setValue={setValue}/>}
        </MuiThemeProvider>
      </DialogContent>
      <DialogActions>
        {modal.step === STEP_ROOM &&
          <>
            <Button onClick={() => closeModal()}>キャンセル</Button>
            <Button onClick={() => onNext()}>次へ</Button>
          </>
        }
        {modal.step === STEP_DETAIL &&
          <>
            <Button onClick={() => handlePrev()}>戻る</Button>
            <Button onClick={handleSubmit(onNext)}>次へ</Button>
          </>
        }
        {modal.step === STEP_CONFIRM &&
          <>
            <Button onClick={() => handlePrev()}>戻る</Button>
            <Button onClick={handleSubmit(onSubmit)}>予約する</Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
}

export default BookingForm;