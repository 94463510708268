import { Link } from '@material-ui/core';
import React from 'react';

function Inn(props) {
  const {inn} = props;
  return (
    <div style={{
      margin: '2.5em auto',
    }}>
      <p className="inn-name">{inn.introduction?.slogan}</p>
      <hr/>
      <div className="inn-introduction">
        {inn.active && (
          <>
            <p className="inn-link">
                {inn.introduction?.descriptions.map((description, index) => (
                    <span key={index}>{description}<br/></span>
                ))}
            </p>
            <p className="inn-link">
              詳細はこちら
              <Link href={inn.introduction?.link} target="_blank">
                <img src={`images/logo_${inn.id}.png`} alt="details" style={{ height: '2em', marginLeft: '1em' }} />
              </Link>
            </p>
            <dl>
              <dt>・料金</dt>
              <dd className="inn-price">{inn.introduction?.price}</dd>
              <dt>・宿泊可能人数</dt>
              <dd className="inn-capacity">{inn.introduction?.capacity}</dd>
            </dl>
          </>
        )}
      </div>
    </div>
  );
}

export default Inn;