import React from 'react';

function CalHeader() {
  return (
    <div className="calHeader">
      <div>Sun.</div>
      <div>Mon.</div>
      <div>Tue.</div>
      <div>Wed.</div>
      <div>Thu.</div>
      <div>Fri.</div>
      <div>Sat.</div>
    </div>
  );
}

export default CalHeader;