import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../actions/modal';
import { BOOKING_STATUS, INNS } from '../constants';
import { formatDateToString } from '../utils/shared';

function CalDate(props) {
  const dispatch = useDispatch();
  const {bookings, dates} = useSelector((state) => state);
  const {firstDayOfMonth} = dates;
  const {date} = props;
  const formatDate = formatDateToString(date);
  const invisible = formatDate.substr(0, 7) !== formatDateToString(firstDayOfMonth).substr(0, 7) ? 'invisible' : '';
  const pastDate = formatDate < formatDateToString(new Date()) ? 'past-date' : '';
  const bookingsOfDay = bookings.filter((booking) => {
    return (booking.startDate <= formatDate && formatDate < booking.endDate);
  });

  return (
    <div className={`calDate ${pastDate} ${invisible}`}>
      <p className="date">{date.getDate()}</p>
      {INNS.map((inn, index) => {
        let numbersOfPaying = {}
        let numberOfBookings = 0;
        inn.rooms.map((room) => {
          const roomCapacity = room.capacity
          const bookingsOfRoom = bookingsOfDay.filter((booking) => booking.inn === inn.name && booking.room === room.name)
          if (room.dormitory) {
            // ドミトリーは人数が上限に達しているかどうか判断する
            let numberOfPaying = 0
            for (let i = 0; i < bookingsOfRoom.length; i++) {
              const event = bookingsOfRoom[i];
              if (event.numberOfPaying === 0) {
                // 人数のない予約（人数で判断する前の予約）があれば埋まったものとする
                numberOfPaying += roomCapacity;
                break;
              } else numberOfPaying += event.numberOfPaying;
            }
            if (numberOfPaying >= roomCapacity) numberOfBookings++
            numbersOfPaying[room.name] = numberOfPaying;
          } else {
            // それ以外は予約があるかどうかで判断する
            if (bookingsOfRoom.length > 0) {
              numberOfBookings++;
              numbersOfPaying[room.name] = roomCapacity;
            }
          }
        })

        const numberOfRooms = inn.rooms ? inn.rooms.length : 1;
        const numberOfRoomsRemaining = numberOfRooms - numberOfBookings;
        const bookingStatus = numberOfRoomsRemaining === 0 ? BOOKING_STATUS.FULL
          : inn.rooms && numberOfBookings === 1 ? BOOKING_STATUS.FEW : BOOKING_STATUS.VACANT;

        const openModal = () => {
          let _rooms = [];
          inn.rooms.forEach((room) => {
            if (room.name in numbersOfPaying) {
              // 予約があれば人数が上限に達しているか
              if (numbersOfPaying[room.name] < room.capacity) {
                // 人数が上限に達していなければ部屋を選択できる
                // 補足：ドミトリー以外は、予約があれば実際の人数に関わらずnumbersOfPayingに上限人数を設定しているため同じ条件で確認できる
                _rooms.push(room)
              }
            } else {
              // 予約がなければ部屋を選択できる
              _rooms.push(room)
            }
          })
          const _checkOut = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
          const modal = {
            open: true,
            inn: inn.name,
            rooms: _rooms,
            formItem: inn.formItem,
            checkIn: date,
            checkOut: _checkOut,
            bookingsOfDay,
          };
          dispatch(setModal(modal));
        };

        return (
          <dl key={index} className="status">
            <dt>{inn.name}</dt>
            {bookingStatus !== BOOKING_STATUS.FULL &&
              <dd className={'clickable-true'} id={inn.name} onClick={openModal}>{bookingStatus}</dd>
            }
            {bookingStatus === BOOKING_STATUS.FULL &&
              <dd className={'clickable-false'} id={inn.name}>{bookingStatus}</dd>
            }
          </dl>
        );
      })}
    </div>
  );
}

export default CalDate;