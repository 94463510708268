import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { handleInitialData } from '../actions/shared';
import Calendar from './Calendar';
import Footer from './Footer';
import Header from './Header';
import Inns from './Inns';
import Plans from './Plans';
import Info from './Info';
import { animateScroll as scroll } from "react-scroll";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleInitialData());
  }, [dispatch]);

  const {loading, dates} = useSelector((state) => state);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="App">
      <Header/>
      <Inns/>
      <Plans/>
      <Info/>
      <Footer/>
      {(!loading && Object.keys(dates).length) && (
        <Calendar className="calendar"/>
      )}
      {loading && (
        <ClipLoader/>
      )}
      <div onClick={scrollToTop} className="back-to-top">Back to Top</div>
    </div>
  );
}

export default App;