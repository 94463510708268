import React from 'react';
import { INNS } from '../constants';

function CalHeaderForList() {
  return (
    <thead>
      <tr>
        <th></th>
        <th></th>
        {INNS.map((inn, index) => (
          <th className="calendar-list-th-inn" key={index}>{inn.name}</th>
        ))}
      </tr>
    </thead>
  );
}

export default CalHeaderForList;