export const getFirstDay = (date, numberOfMonth = 0) => new Date(date.getFullYear(), date.getMonth() + numberOfMonth, 1);

export function getStartDate(firstDayOfMonth) {
  if (firstDayOfMonth instanceof Date) {
    return new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      firstDayOfMonth.getDate() - firstDayOfMonth.getDay()
    );
  } else {
    return null;
  }
}

export const formatDateToString = (date) => {
  if (date instanceof Date) {
    const _year = date.getFullYear();
    const _month = ('00' + (date.getMonth() + 1)).slice(-2);
    const _date = ('00' + date.getDate()).slice(-2);
    return _year + '-' + _month + '-' + _date;
  } else {
    return null;
  }
};

export const formatStringToDate = (date) => {
  try {
    const _dateArray = date.split('-');
    const _year = _dateArray[0];
    const _month = _dateArray[1] - 1;
    const _date = _dateArray[2];
    return new Date(_year, _month, _date);
  } catch (e) {
    return null;
  }
};
