import { INNS } from '../constants';
import { getInitialData } from '../utils/api';
import { receiveBookings } from './bookings';
import { setDates } from './dates';
import { hideLoading, showLoading } from './loading';
import { setModal } from './modal';

export function handleInitialData() {
  function convertToBookings(items) {
    let bookings = [];
    items.forEach((item) => {
      const [inn, room, condition, numberOfPaying] = 'summary' in item ? item.summary.split(',') : '';
      const booking = {
        inn: inn,
        room: room,
        condition: condition,
        numberOfPaying: numberOfPaying ? Number(numberOfPaying) : 0,
        startDate: item.start.date,
        endDate: item.end.date,
        status: item.status
      };
      const inns = INNS.map((inn) => inn.name);
      if (inns.includes(booking.inn) &&
        typeof booking.startDate !== 'undefined' &&
        typeof booking.endDate !== 'undefined' &&
        booking.status !== 'cancelled') {
        bookings.push(booking);
      }
    });
    return bookings;
  }

  return (dispatch) => {
    dispatch(showLoading());
    return getInitialData().then(({events}) => {
      const date = new Date();
      dispatch(setDates(date));
      dispatch(setModal({open: false}));
      const items = events.data.items
      dispatch(receiveBookings(convertToBookings(items)));
      dispatch(hideLoading());
    });
  };
}