import React from 'react';
import CalDate from './CalDate';

function CalWeek(props) {
  const {startDayOfWeek} = props;
  let datesOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(startDayOfWeek.getFullYear(), startDayOfWeek.getMonth(), startDayOfWeek.getDate() + i);
    datesOfWeek.push(date);
  }

  return (
    <div className="calWeek">
      {datesOfWeek.map((date, index) => {
        return (
          <CalDate key={index} date={date}/>
        );
      })}
    </div>
  );
}

export default CalWeek;